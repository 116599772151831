var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-view l-container--full-height l-container--large p-register"},[_c('Sidebar',{staticClass:"o-sidebar"},[_c('div',{staticClass:"o-sidebar__inner"},[_c('div',{staticClass:"o-sidebar__top-image"},[_c('img',{attrs:{"src":require("@/assets/images/generic/afbeelding-evenement-4x.jpg"),"alt":"Open"}})]),_c('div',{staticClass:"o-sidebar__content"},[_c('p',{staticClass:"o-sidebar__title o-sidebar__title-xl"},[_vm._v("vragen? Contacteer ons")]),_c('p',{staticClass:"o-sidebar__info"},[_vm._v(" Bel ons "),_c('br'),_c('a',{attrs:{"href":"tel:+3292431119"}},[_vm._v("+32 (0)9 243 11 19")])]),_c('br'),_c('p',{staticClass:"o-sidebar__info"},[_vm._v(" Mail ons "),_c('br'),_c('a',{attrs:{"href":'mailto:' + _vm.infoEmail}},[_vm._v(_vm._s(_vm.infoEmail))])])])])]),_c('div',{staticClass:"o-page o-page--white"},[_c('div',{staticClass:"p-register__content"},[_c('h1',{staticClass:"p-register__title"},[_vm._v("Welkom bij ikwatersport.be")]),(!_vm.registerSuccessful)?_c('div',[_vm._m(0),_c('ValidationObserver',{ref:"form",attrs:{"slim":""}},[_c('form',{staticClass:"p-register__form",attrs:{"id":"registerForm"},on:{"submit":function($event){$event.preventDefault();return _vm.registerSubmit($event)}}},[_c('div',{staticClass:"p-register__name-wrapper"},[_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"rules":"required","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Voornaam "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.registerObj.firstName),expression:"registerObj.firstName",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"firstname","placeholder":"Voornaam"},domProps:{"value":(_vm.registerObj.firstName)},on:{"change":function($event){return _vm.$set(_vm.registerObj, "firstName", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2246541458)}),_c('ValidationProvider',{staticClass:"a-input__label p-register__form-label--surname",attrs:{"rules":"required","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Achternaam "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.registerObj.lastName),expression:"registerObj.lastName",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"text","name":"lastname","placeholder":"Achternaam"},domProps:{"value":(_vm.registerObj.lastName)},on:{"change":function($event){return _vm.$set(_vm.registerObj, "lastName", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3944561026)})],1),_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"rules":"required|email","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" E-Mail "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.registerObj.email),expression:"registerObj.email",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"email","name":"email","placeholder":"E-mail","autocomplete":"new-email"},domProps:{"value":(_vm.registerObj.email)},on:{"change":function($event){return _vm.$set(_vm.registerObj, "email", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1230260511)}),_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"vid":"confirm","rules":"required|verify_password","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Wachtwoord "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.registerObj.password),expression:"registerObj.password",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"password","name":"password","placeholder":"Wachtwoord","autocomplete":"new-password"},domProps:{"value":(_vm.registerObj.password)},on:{"change":function($event){return _vm.$set(_vm.registerObj, "password", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2123362380)}),_c('ValidationProvider',{staticClass:"a-input__label",attrs:{"rules":"required|confirmed:confirm","tag":"label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._v(" Herhaal wachtwoord "),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.registerObj.repeatPassword),expression:"registerObj.repeatPassword",modifiers:{"lazy":true}}],staticClass:"a-input",attrs:{"type":"password","name":"repeat-password","placeholder":"Wachtwoord"},domProps:{"value":(_vm.registerObj.repeatPassword)},on:{"change":function($event){return _vm.$set(_vm.registerObj, "repeatPassword", $event.target.value)}}}),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3143777171)}),_c('p',{staticClass:"p-register__interest-title u-margin-top-lg"},[_vm._v("Interesse in")]),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(_vm.interestError))]),_c('div',{staticClass:"p-register__interest-card-container"},_vm._l((_vm.disciplineList),function(discipline){return _c('InterestCard',{key:discipline.name,attrs:{"interest":discipline,"isActive":_vm.disciplineIsActive(discipline)},on:{"addInterestId":_vm.addInterestId,"removeInterestId":_vm.removeInterestId}})}),1),_c('div',{staticClass:"u-margin-top"},[_c('ValidationProvider',{attrs:{"rules":"required:true","persist":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"a-input__checkbox-container"},[_vm._v(" Ik ga akkoord met de "),_c('a',{staticClass:"a-link--underline",attrs:{"href":_vm.urlConditions}},[_vm._v("algemene voorwaarden")]),_vm._v(" & "),_c('a',{staticClass:"a-link--underline",attrs:{"href":_vm.urlPolicy}},[_vm._v("privacy policy")]),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.registerObj.consentGeneralConditions),expression:"registerObj.consentGeneralConditions",modifiers:{"lazy":true}}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.registerObj.consentGeneralConditions)?_vm._i(_vm.registerObj.consentGeneralConditions,null)>-1:(_vm.registerObj.consentGeneralConditions)},on:{"change":[function($event){var $$a=_vm.registerObj.consentGeneralConditions,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.registerObj, "consentGeneralConditions", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.registerObj, "consentGeneralConditions", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.registerObj, "consentGeneralConditions", $$c)}},function($event){!_vm.registerObj.consentGeneralConditions
                        ? (_vm.registerObj.consentGeneralConditions = null)
                        : _vm.registerObj.consentGeneralConditions}]}}),_c('span',{staticClass:"a-input__checkbox-checkmark"})]),_c('span',{staticClass:"a-input__validation-message"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,602265480)})],1),_c('button',{staticClass:"a-button a-button--primary"},[_vm._v("Registreren")])],1)])],1):_c('div',[_vm._m(1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"p-register__subtitle"},[_vm._v(" Heb je al een account? "),_c('a',{staticClass:"a-link--underline",attrs:{"href":"/login"}},[_vm._v("Log dan hier in.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Account succesvol aangemaakt. "),_c('a',{staticClass:"a-link--underline",attrs:{"href":"/login"}},[_vm._v("Inloggen.")])])}]

export { render, staticRenderFns }