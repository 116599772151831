import {
  mapActions, mapState, mapGetters,
} from 'vuex';

import {
  Sidebar,
  InterestCard,
} from '@/components';

import isServer from '../../utils/isServer';

if (!isServer) {
  const { required, email, confirmed } = require('vee-validate/dist/rules');
  const { extend } = require('vee-validate');

  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });

  extend('email', {
    ...email,
    message: 'Een geldig e-mailadres is vereist.',
  });

  extend('confirmed', {
    ...confirmed,
    message: 'De wachtwoorden komen niet overeen.',
  });

  extend('verify_password', {
    // eslint-disable-next-line no-unused-vars
    message: 'Wachtwoord moet ten minste 8 tekens zijn en bestaan uit ten minste één hoofdletter, één speciaal teken & één cijfer.',
    validate: (value) => {
      // eslint-disable-next-line no-useless-escape
      const strongRegex = new RegExp('^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$');

      // the regex above checks if the value is less than 8, doesn't include upper/lowercase characters and it doesn't have a symbol
      // if it is false, your password is valid because it didn't trigger the faultregex
      return !strongRegex.test(value);
    },
  });
}

export default {
  metaInfo: {
    title: 'Register page',
  },
  name: 'Register',
  components: {
    [Sidebar.name]: Sidebar,
    [InterestCard.name]: InterestCard,
  },
  props: {},
  data: () => ({
    registerObj: {
      email: '',
      password: '',
      repeatPassword: '',
      firstName: '',
      lastName: '',
      consentGeneralConditions: null, // NIET FALSE voor vee-validate
      disciplines: [],
    },
    urlConditions: process.env.VUE_APP_URL_CONDITIONS,
    urlPolicy: process.env.VUE_APP_URL_POLICY,
    interestError: '',
  }),
  computed: {
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
    ...mapState('account', {
      status: 'status',
      registerSuccessful: 'registerSuccessful',
    }),
    infoEmail() {
      return process.env.VUE_APP_INFO_EMAIL_ADDRESS;
    },
  },
  methods: {
    ...mapActions('discipline', {
      initialiseDisciplineList: 'FETCH_DATA_DISCIPLINE_LIST',
    }),
    ...mapActions('account', {
      registerAction: 'REGISTER',
    }),
    disciplineIsActive(discipline) {
      return this.registerObj.disciplines.includes(discipline.id);
    },
    registerSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);

            this.$refs.form.refs[errors[0]].$el.scrollIntoView();
            window.scrollBy(0, -75);
          }, 100);
        } else if (this.registerObj.disciplines.length <= 0) {
          this.interestError = 'Minstens 1 interesse vereist.';
        } else {
          this.registerAction(this.registerObj).catch((err) => {
            this.$toasted.show(err);
          });
        }
      });
    },
    addInterestId(id) {
      this.interestError = '';
      this.registerObj.disciplines.push(id);
    },
    removeInterestId(id) {
      const index = this.registerObj.disciplines.indexOf(id);
      this.registerObj.disciplines.splice(index, 1);
    },
  },
  created() {
    this.initialiseDisciplineList();
  },
};
